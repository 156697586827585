header {
  padding: 30px 20px;
  display: flex !important;
  .leftSection {
    flex: 0 0 50%;
    justify-content: flex-start;
    display: flex;
    .logo {
      background: url(../../assets/images/thermo-fisher-scientific.png)
        no-repeat;
      width: 213px;
      height: 46px;
      cursor: pointer;
    }
    .logoTxt {
      margin-left: 40px;
      font-size: 28px;
      color: #54585a;
      align-self: center;
      font-family: "NeueHelveticaBd";
      cursor: pointer;
    }
  }
  .rightSection {
    flex: 0 0 50%;
    justify-content: flex-end;
    display: flex;
    .search {
      display: block;
      border: 1px solid #e2e3e4;
      padding: 10px;
      border-radius: 10px;
    }
    .search:focus{
      border: 1px solid #e2e3e4;
    }
    .profileOuter {
      cursor: pointer;
      padding: 0 15px 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      &:after {
        background: url(../../assets/images/user.png) no-repeat;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 46px;
        height: 46px;
        display: none;
      }
      &:before {
        background: url(../../assets/images/icn-dropdown.png) no-repeat right;
        width: 7px;
        height: 4px;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
      }
      .profileName {
        color: #54585a;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3px;
        text-transform: capitalize;
      }
      .mleft35 {
        margin-left: 35px;;
        font-size: 14px;
      }
      .logout {
        font-size: 14px;
      }
      .profileEmail {
        color: #54585a;
        font-size: 14px;
      }
    }
  }

  .dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .dropbtn:hover, .dropbtn:focus {
    background-color: #3e8e41;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    position: absolute;
    background-color: #ffffff;
    width: 224px;
    overflow: auto;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    right: 0;
    z-index: 1;
    top:50px;
    border-radius: 0px 0 15px 15px;
    display: none;
  }
  
  .dropdown-content a {
    padding: 16px 16px 16px 0;
    text-decoration: none;
    display: block;
    font-family: NeueHelvetica;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #484c46;
    text-align: center;
  }
  .dropdown-content a:nth-child(1){
    border-bottom: 1px solid #e2e3e4;
    &:after {
      background: url(../../assets/images/icn-config.png) no-repeat;
      content: "";
      position: absolute;
      left: 20px;
      top: 7px;
      width: 31px;
      height: 32px;
    }
  }
  .dropdown-content a:nth-child(2){
    &:after {
      background: url(../../assets/images/icn-logout.png) no-repeat;
      content: "";
      position: absolute;
      left: 24px;
      top: 60px;
      width: 31px;
      height: 31px;
    }
  }
  
  .dropdown a:hover {background-color: #ddd;}
  
  .show {display: block;}

  .search-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    width: 224px;
    overflow: auto;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    right: 250;
    z-index: 1;
    top:50px;
    border-radius: 0px 0 15px 15px;
  }
}
