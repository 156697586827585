.cxContainer {
  display: inline-block;
  width: 100%;
  padding: 0 20px;
  min-height: calc(100vh - 106px);
}
.leftContent {
  float: left;
  width: 65%;
}
.scoreGrid {
  display: flex;
  margin-bottom: 10px;
}
.layoutBx {
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  min-height: 253px;
  margin-bottom: 10px;
  float: left;
  width: calc(100% - 10px) !important;
}
.cxHeading {
  padding: 0 20px;
  font-family: "NeueHelvetica";
  font-size: 16px;
  font-weight: bold;
  color: #54585a;
  border-bottom: 1px solid #e2e3e4;
  margin: 0;
  height: 50px;
  line-height: 56px;
}
.underDesign {
  height: 190px;
  width: 100%;
  font-family: NeueHelvetica;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #54585a;
  padding: 10%;
}
.nbaContainer {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.griditem {
  border: 1px solid #f2f0f0;
	padding: 20px;
	font-size: 14px;
	text-align: center;
	border-radius: 14px;
	cursor: pointer;
}
.recommended {
  color: #fff;
  background-color: #e71316;
}
.groupTopSection {
  padding: 0 0 20px;
  display: flex;
}

.innerLayout {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  box-sizing: border-box;
  border-right: 1px solid #e2e3e4;
  &:last-child {
    border-right: 0 none;
  }
}

.bodyTitle {
  color: #78797c;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

.bodyValue {
  color: #54585a;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.rightContent {
  .groupView {
    .groupViewHeader {
      border-radius: 14px 14px 0 0;
      background: #e71316;
      padding: 20px 20px 15px;
      color: #fff;
    }
    .groupViewBody {
      background: #fff;
      border-radius: 0 0 14px 14px;
      padding-top: 20px;
      margin-bottom: 20px;
    }
  }
}