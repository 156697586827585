.config_container {
	padding: 0 20px;
	font-family: "NeueHelvetica";
	.ant-spin {
        position: relative;
	left: 40%;
}
    }

.config_edit_panel {
	.dropdown {
            background-color: red !important;
}
    }

.truncate {
	width: 250px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.successMsg {
	color: #22bb33;
}

.customBtn {
	background-color: #e71316 !important;
	border: 1px solid #e71316 !important;
}

.customBtnCancel {
	background-color: #ccc !important;
	border: 1px solid #ccc !important;
	color: #000;
}

.customBtnCancel:hover {
	background-color: #ccc;
	color: #000;
}

.customBtn:hover {
	background-color: #e71316;
}

.table_header {
	background-color: #e71316;
	color: #fff;
	height: 40px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.child_kpi:nth-child(even) {
	background: #CCC;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
}

.child_kpi:nth-child(odd) {
	background: #FFF;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
}

.unit {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
}

.borderBtm {
	border-bottom: 1px solid;
}

.mTop10 {
	margin-top: 10px;
}

.marTop15 {
	margin-top: 15px;
}

.discardMsg {
	color: #CA0B00;
}

.operatorBtw {
	display: block;
	width: 29px;
	position: relative;
	top: 8px;
	left: -5px;
}

.dispFlex {
	display: flex;
}

.mTop20 {
	margin-top: 20px;
}

.kpiPanel {
	background-color: rgba(0, 0, 0, 0.1);
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
	border-radius: 14px;
}

.rowHeight {
	height: 100px;
	;
}

.mTop10 {
	margin-top: 10px;
	;
}

.resetMargin {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.resetPadding {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.dropdownMenu1 {
	width: 100% !important;
}

.resetRighPadding {
	padding-right: 0px !important;
}

.counting {
	position: absolute;
	left: 22px;
	;
}

.center {
	text-align: center;
}

.number_sign {
	position: relative;
	left: -180px;
}

.config_container .btn_success_config {
	background-color: #fff !important;
	color: #000 !important;
	border-color: #fff !important;
	position: relative;
	text-align: left;
}

.padLeft0 {
	padding-left: 0px !important;
}

.config_container .btn_success_config::after {
	display: none !important;
}

.config_container .btn_success_config:focus {
	box-shadow: none !important;
}

.btn_success_config {
	width: 100%;
}

.styleArrow::after {
	display: inline-block;
	margin-left: 0;
	vertical-align: 0;
	content: "";
	position: absolute;
	top: 15px;
	right: 9px;
	border-top: 6px solid #fff;
	border-right: 6px solid transparent;
	border-bottom: 0 solid;
	border-left: 6px solid transparent;
}

.styleArrow {
	background-color: red;
	height: 100%;
	width: 30px;
	position: absolute;
	top: 0;
	color: red;
	right: 0;
	border-radius: 0px .25rem .25rem 0;
}

.applybg1 {
	background-color: #CCC !important;
}