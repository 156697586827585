.cxContainer {
  display: inline-block;
  width: 100%;
  padding: 0 20px;
}

//CX Index Layout

.cxIndexContainer {
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
}

.cxHeading {
    padding: 0 20px;
    font-family: "NeueHelvetica";
    font-size: 16px;
    font-weight: bold;
    color: #54585a;
    border-bottom: 1px solid #e2e3e4;
    margin: 0;
    height: 50px;
    line-height: 56px;
}

.indexContent {
  padding: 20px 0;
  display: flex;
}

.chartLeft {
  padding: 0;
  flex: 0 0 30%;
}

.emptyGauge {
  background: url(../../assets/images/gauge-chart.png) no-repeat;
  height: 120px;
  width: 310px;
}

.indexChartLegend {
  ul {
    flex-wrap: wrap;
    display: flex;
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      margin: 5px 0 0 0;
      flex: 1 0 50%;
      .dash {
        margin-left: 20px;
      }
      .percentValue1 {
        font-size: 18px;
        font-weight: bold;
        color: #4aa707;
        margin-left: 20px;
        
      }
      .percentValue2 {
        font-size: 18px;
        font-weight: bold;
        color: #d01214;
        margin-left: 20px;
        
      }
      .percentValue {
        font-size: 18px;
        font-weight: bold;
        color: #54585a;
        margin-left: 20px;
        
      }
      .percentLabel {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #54585a;
          display: table-cell;
          vertical-align: middle;
          height: 25px;
      }
      .volatility {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #54585a;
        margin-left: 20px;
      }
    }
  }
  .arrowUp,
        .arrowDown {
          background: url(../../assets/images/up-arrow.png) no-repeat;
          width: 8px;
          height: 14px;
          display: inline-block;
          margin-left: 5px;
        }
        .arrowNoChange {
          background: url(../../assets/images/no-change-arrow.png) no-repeat;
          width: 14px;
          height: 11px;
          display: inline-block;
          margin-left: 5px;
        }
        .arrowDown {
          transform: rotate(180deg);
        }
}

.indexDesc {
  flex: 0 0 40%;
  border-left: 1px solid #e2e3e4;
  padding-left: 30px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #54585a;
  }
  ul {
    opacity: 0.3;
    padding-left: 0;
    list-style: none;
    li {
      font-size: 14px;
      font-weight: normal;
      color: #54585a;
      margin-bottom: 20px;
      position: relative;
      padding-left: 20px;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        background-color: #e71316;
        width: 10px;
        height: 10px;
        border-radius: 1rem;
      }
    }
  }
}